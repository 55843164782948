.image-gallery {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-item {
    margin: 10px;
    text-align: center;
  }
  
  .image-item img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .images-grid {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
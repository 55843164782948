.contenedor-document {
  padding: 40px;
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 39%);
  width: 500px;
}

.app-container {
padding:50px;
}


.contenedor-document h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.contenedor-document p {
  font-size: 16px;
  text-align: center;
  color: #555;
}

.btn-submit {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #d13b30;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.btn-submit:hover {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #d13b30;
}

.contenedor-botones {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.message-sync {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px; 
  max-width: 90%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333; 
}

.info-message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-message i {
  font-size: 20px;
  color: #17a2b8;
}

.info-message small {
  font-size: 14px;
  color: #333;
}

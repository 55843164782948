/* MenuUser.css */


.user-info span {
  margin-right: 10px;
}

.user-info span:last-child {
  margin-right: 0;
}

.menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-buttons {
  margin-top: 15px;
}

.logout-button {
  margin-right: 10px;
  padding: 1px 15px;
  border: none;
  background-color: #d13b30;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
/* Establece la imagen de fondo */
/*
[data-amplify-authenticator] {
  background-image: url('../../images/Background\ B1z.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}*/

/* Ajusta el contenedor del formulario */
[data-amplify-authenticator] [data-amplify-router] {
  background-color:#00164a;
  padding: 70px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  border: 0;

}

/* Cambiar color de los botones y links a #d13b30 */
.amplify-button--primary,
.amplify-tabs__item--active,
.amplify-button--link {
  --amplify-internal-button-background-color: #d13b30;
  --amplify-internal-button-color: #fff;
  color: white;
}

.amplify-button--primary:hover {
  --amplify-internal-button-background-color: #d13b30;
}


.amplify-button:hover {
  --amplify-internal-button-background-color: #f7b11d;
  --amplify-internal-button-color: white;
}



/* Otros ajustes */
.login-form-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #d13b30;
}

.login-form-field input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 1.2rem;
  width: 100%;
}

.login-footer {
  margin-top: 3rem;
  text-align: center;
  border-top: 1px solid #bebebe;
}

.amplify-label {
  color: hsl(0deg 0% 100%);
}

.amplify-tabs__item {
  color: white;
}

.amplify-icon {
  color: white;
}



img.logo-login {
  top: 80px;
  position: relative;
}

/* Hacer responsive */
@media (max-width: 768px) {
  .login-form-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .login-form-field input {
    font-size: 1rem;
  }

  .login-footer {
    margin-top: 2rem;
  }

  img.logo-login {
    width: 120px;
    height: 120px;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    padding: 70px 15px 15px 15px;
  }
}

@media (max-width: 480px) {
  .login-form-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .login-form-field input {
    font-size: 0.9rem;
  }

  .login-footer {
    margin-top: 1.5rem;
  }


  [data-amplify-authenticator] [data-amplify-router] {
    padding: 70px 15px 15px 15px;
  }

  .login-form-policy {
    color: white;
    width: 80%;
    margin: auto;
}
}

.amplify-input{
  color: white;
}

.amplify-tabs__item--active {
  color:#d13b30 ;
  border-color: #d13b30;
}

.amplify-tabs__item:hover {
  color: #d13b30;
}

.amplify-tabs__item:active {
  border-color: #d13b30;
  box-shadow: var(--amplify-components-tabs-item-active-box-shadow);
  color: #d13b30;
}

.amplify-text--error {
  color: hsl(0deg 100% 48.82%);
}

.amplify-button {
  --amplify-internal-button-color: white;
}

.amplify-text {

  color: white;
}

.amplify-heading {
  color: #d13b30;
}

.amplify-input:focus {
  border-color: white;
}

.amplify-button:hover {
  --amplify-internal-button-border-color: #f7b11d;
}

[data-amplify-authenticator][data-variation=modal] {
  background: white;
}